import {
	currentTargetOptional,
	currentTargetRequired,
	getAttributeOrThrow,
	qsOptional,
	qsRequired,
} from '@/scripts/functions'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { VariantSelects } from '@/scripts/product/product-options'
import { ProductVariant } from '@/scripts/types/api'

export class SelectDisclosure extends UcoastEl {
	static htmlSelector = 'select-disclosure'
	elements: {
		input?: HTMLInputElement | HTMLSelectElement
		button: HTMLButtonElement
		panel: HTMLElement
	}
	suppressSubmit: boolean
	continueFlow = false
	constructor() {
		super()
		this.elements = this.getElements()
		this.suppressSubmit = this.hasAttribute('data-uc-supress-submit')
	}

	getElements() {
		return {
			input: qsOptional<HTMLInputElement | HTMLSelectElement>('[data-uc-select-value]', this),
			button: qsRequired<HTMLButtonElement>('button', this),
			panel: qsRequired('[data-uc-disclosure-list-wrapper]', this),
		}
	}

	override connectedCallback() {
		super.connectedCallback()
		this.onConnectedCallback()
	}

	onConnectedCallback() {
		this.elements = this.getElements()
		this.elements.button.addEventListener('click', this.openSelector.bind(this))
		this.addEventListener('keyup', this.onContainerKeyUp.bind(this))

		this.querySelectorAll('a').forEach((item) =>
			item.addEventListener('click', this.onItemClick.bind(this))
		)
	}

	hidePanel() {
		this.elements.button.setAttribute('aria-expanded', 'false')
		this.elements.panel.setAttribute('hidden', '')
		this.removeAttribute('data-uc-open')
		document.body.removeEventListener('click', this.closeSelector.bind(this))
	}

	onContainerKeyUp(event: KeyboardEvent) {
		if (event.code.toUpperCase() !== 'ESCAPE') return
		this.hidePanel()
		this.elements.button.focus()
	}

	onItemClick(event: MouseEvent) {
		event.preventDefault()
		if (!this.elements.input) return
		const form = qsRequired<HTMLFormElement>('form', this)
		const currentTarget = currentTargetRequired(event)
		this.elements.input.value = currentTarget.dataset.value ?? ''
		form.dispatchEvent(new Event('input', { bubbles: true }))
		this.querySelectorAll('a').forEach((item) => item.classList.remove('active'))
		currentTarget.classList.add('active')
		if (this.suppressSubmit) {
			const selectText = qsRequired('[data-uc-select-text]', this)
			selectText.textContent = `${currentTarget.textContent} +`
			this.closeSelector(event)
		} else {
			form.submit()
		}
	}

	openSelector(_: Event, continueFlow = false) {
		const disabled = this.elements.button.hasAttribute('data-uc-disabled')
		if (disabled) return
		this.setAttribute('data-uc-open', '')
		this.elements.panel.focus()
		this.elements.panel.toggleAttribute('hidden')
		this.elements.button.setAttribute(
			'aria-expanded',
			(this.elements.button.getAttribute('aria-expanded') === 'false').toString()
		)
		window.setTimeout(() => {
			const bodyListener = (event: MouseEvent) => {
				this.closeSelector(event)
				document.body.removeEventListener('click', bodyListener)
			}
			document.body.addEventListener('click', bodyListener)
		}, 10)
		if (continueFlow) {
			this.continueFlow = true
		}
	}

	closeSelector(event: MouseEvent) {
		const currentTarget = currentTargetOptional(event)
		if (!currentTarget) {
			this.hidePanel()
			return
		}
		if (
			this.elements.panel.contains(currentTarget) ||
			this.elements.button.contains(currentTarget) ||
			this.elements.panel === currentTarget
		)
			return
		this.hidePanel()
	}
}

export class SelectDisclosureMenu extends SelectDisclosure {
	static override htmlSelector = 'select-disclosure-menu'
	constructor() {
		super()
	}
	override onConnectedCallback() {
		this.elements = this.getElements()
		this.elements.button.addEventListener('click', this.openSelector.bind(this))
		this.addEventListener('keyup', this.onContainerKeyUp.bind(this))
	}
	override getElements() {
		return {
			input: qsOptional<HTMLInputElement | HTMLSelectElement>('[data-uc-select-value]', this),
			button: qsRequired<HTMLButtonElement>('button', this),
			panel: qsRequired('[data-uc-disclosure-list-wrapper]', this),
		}
	}
}

export class SelectDisclosureWithoutForm extends SelectDisclosure {
	static override htmlSelector = 'select-disclosure-without-form'
	constructor() {
		super()
	}
	override getElements() {
		return {
			input: qsOptional<HTMLInputElement | HTMLSelectElement>('[data-uc-select-value]', this),
			button: qsRequired<HTMLButtonElement>('button[data-uc-open]', this),
			panel: qsRequired('[data-uc-disclosure-list-wrapper]', this),
		}
	}
	override onConnectedCallback() {
		this.elements = this.getElements()

		this.elements.button.addEventListener('click', this.openSelector.bind(this))
		this.addEventListener('keyup', this.onContainerKeyUp.bind(this))

		this.querySelectorAll('[data-uc-variant-select-option]').forEach((item) => {
			item.addEventListener('click', this.onItemClick.bind(this))
		})
	}
	override onItemClick(event: MouseEvent) {
		event.preventDefault()
		if (!this.elements.input) return
		qsRequired('body').classList.add('pdp-hide-on-select')
		this.querySelectorAll('[data-uc-variant-select-option]').forEach((item) => {
			item.removeAttribute('selected')
		})
		const currentTarget = currentTargetRequired(event)
		currentTarget.setAttribute('selected', 'selected')

		this.elements.input.value = currentTarget.dataset.value ?? ''
		const selectText = qsRequired('[data-uc-select-text]', this)
		selectText.innerHTML = currentTarget.innerHTML.replace(' - Unavailable', '')
		this.closeSelector(event)
		this.elements.input.dispatchEvent(new Event('change', { bubbles: true }))
		if (this.continueFlow) {
			this.continueFlow = false
			const flowStepStr = getAttributeOrThrow('data-uc-flow-step', this)
			const flowStep = parseInt(flowStepStr, 10) + 1
			const nextStep = qsOptional<SelectDisclosureWithoutForm>(
				`[data-uc-flow-step="${flowStep}"]`
			)
			if (nextStep) {
				nextStep.openSelector(event, true)
			}
		}
	}
}

export class ProductColorPicker extends SelectDisclosure {
	static override htmlSelector = 'product-color-picker'
	variantData: ProductVariant[] = []
	constructor() {
		super()
	}
	override getElements() {
		return {
			input: qsOptional<HTMLInputElement | HTMLSelectElement>('[data-uc-select-value]', this),
			button: qsRequired<HTMLButtonElement>('button[data-uc-open]', this),
			panel: qsRequired('[data-uc-disclosure-list-wrapper]', this),
		}
	}
	override onConnectedCallback() {
		this.elements = this.getElements()

		this.elements.button.addEventListener('click', this.openSelector.bind(this))
		this.addEventListener('keyup', this.onContainerKeyUp.bind(this))

		this.querySelectorAll('[data-uc-variant-select-option]').forEach((item) => {
			item.addEventListener('click', this.onItemClick.bind(this))
		})
	}
	override onItemClick(event: MouseEvent) {
		event.preventDefault()
		const handle = getAttributeOrThrow('data-value', currentTargetRequired(event))
		let url = `/products/${handle}`
		const options = qsRequired<VariantSelects>('variant-selects')
		options.updateMasterId()
		const selectedOption = options.currentVariant.option1
		const currentProductVariants = this.getVariantData(handle)

		const newProductVariantId = currentProductVariants.filter((variant: ProductVariant) => {
			return variant.option1 === selectedOption
		})[0]?.id
		if (newProductVariantId) {
			url += `?variant=${newProductVariantId}&from=${handle}`
		}
		window.location.href = url
	}
	getVariantData(handle: string) {
		const textContent = qsRequired(
			`[type="application/json"][data-uc-product-color-data="${handle}"]`,
			this
		).textContent
		if (!textContent) throw `textContent not found in getVariantData`
		const variantData = JSON.parse(textContent)
		if (!variantData) throw 'variant data undefined'
		return variantData
	}
}
